import _ from 'lodash';
import {Injectable} from '@angular/core';
import connectionIcon from '@icons/resource/connection.svg';

import {buildMessageFormatter} from '@shared/utils/i18n/build-message-formatter';
import {MixpanelEvent, MixpanelEventParams, MixpanelService} from '@shared/services/mixpanel';
import {toSentence} from '@shared/utils/to-sentence';
import {nonFalsy} from '@shared/utils/non-nullable';

import {AuthUser} from '../../services/auth-user';
import {ConnectionAsset} from '../assets-page/assets.types';
import {RootDialogService} from '../../services/root-dialog.service';
import {Adapter, Genie, Hash, Picklist, PicklistItem, SchemaField} from '../../types';
import {isGenieActive} from '../../modules/recipe-editor/recipe.utils';
import {GenieHelper} from '../../services/genie-helper.service';

import {Connection, ConnectionStatus, ConnectionsMixpanelEvents, ExtendedConnection} from './connections.types';

const confirmationDialogFormatter = buildMessageFormatter<{recipeCount: number; connectionName: string}, 'b'>(
  $localize`Deleting <b>{connectionName, select, other {{connectionName}}}</b> will impact <b>{recipeCount, plural, one {# recipe} other {# recipes}}</b> using it.<br/>
  Connections can’t be recovered from Trash, so this action can’t be undone.`,
);

const activeRecipesCountFormatter = buildMessageFormatter<{recipeCount: number}, 'b'>(
  $localize`{recipeCount, plural, one {<b>#</b> active recipe} other {<b>#</b> active recipes}}`,
);

const apiProxyEndpointsCountFormatter = buildMessageFormatter<{endpointsCount: number}, 'b'>(
  $localize`{endpointsCount, plural, one {<b>#</b> API proxy endpoint} other {<b>#</b> API proxy endpoints}}`,
);

const customOauthProfileFieldHintFormatter = buildMessageFormatter<
  {customOauthKeyId: number | undefined},
  'a1' | 'a2' | 'a3'
>(
  $localize`When selected, all requests to the app will use the profile specified <a1>here</a1>.
{customOauthKeyId, select, undefined {} other {You can update selected profile <a2>here</a2>.}}
Create new profile <a3>here</a3>.`,
);

const connectionNameFormatter = buildMessageFormatter<{
  title: string;
  counter: number;
}>(
  $localize`My {counter, selectordinal, =1 {} =2 {second} =3 {third} =4 {fourth} =5 {fifth} =6 {sixth} =7 {seventh} =8 {eighth} =9 {ninth} other {#th} } {title, select, other {{title}}} account`,
);

@Injectable({
  providedIn: 'root',
})
export class ConnectionHelper {
  constructor(
    private authUser: AuthUser,
    private genieHelper: GenieHelper,
    private dialog: RootDialogService,
    private mixpanelService: MixpanelService,
  ) {}

  isConnected(connection: Pick<Connection, 'authorization_status'>): boolean {
    return connection.authorization_status === 'success';
  }

  isConnectionLost(connection: Pick<Connection, 'connection_lost_at'>): boolean {
    return Boolean(connection.connection_lost_at);
  }

  isExtendedConnection(connection: Connection | ExtendedConnection): connection is ExtendedConnection {
    return typeof (connection as ExtendedConnection).input === 'object';
  }

  isCurrentlyUsed(
    connection: Pick<
      Connection,
      | 'alr_connection'
      | 'authorization_status'
      | 'proxy_api_endpoints_count'
      | 'secrets_manager_connection'
      | 'traffic_mirroring_connection'
    >,
    genie?: Genie,
  ): boolean {
    return Boolean(
      (this.isConnected(connection) &&
        (connection.alr_connection ||
          connection.proxy_api_endpoints_count > 0 ||
          connection.traffic_mirroring_connection ||
          connection.secrets_manager_connection)) ||
        isGenieActive(genie),
    );
  }

  canDeleteConnection(
    connection: Pick<
      Connection,
      | 'alr_connection'
      | 'authorization_status'
      | 'proxy_api_endpoints_count'
      | 'secrets_manager_connection'
      | 'traffic_mirroring_connection'
    >,
    genie?: Genie,
  ): boolean {
    return !this.isCurrentlyUsed(connection, genie);
  }

  getDeleteMessage(
    connection: Pick<Connection, 'alr_connection' | 'proxy_api_endpoints_count' | 'traffic_mirroring_connection'>,
    genie?: Genie,
  ): string | null {
    if (isGenieActive(genie)) {
      return this.genieHelper.getDeleteConnectionDisabledDescription(genie);
    }

    const usages = [
      connection.alr_connection && $localize`audit log streaming`,
      connection.proxy_api_endpoints_count > 0 && $localize`proxy endpoints`,
      connection.traffic_mirroring_connection && $localize`traffic mirroring`,
    ].filter(nonFalsy);

    return usages.length ? $localize`You can’t delete a connection used by ${toSentence(usages)}` : null;
  }

  getConnectionIdentity(connection?: Connection): Connection['identity'] | undefined {
    return this.authUser.oem_customer_with_generated_email && connection?.provider === 'workato_app'
      ? this.authUser.oem_generated_email_replacement
      : connection?.identity;
  }

  getDependencyGraphUrl(connection: Connection): string {
    return `/dashboard/assets?assetType=connection&assetId=${connection.id}`;
  }

  async showDeleteConfirmation(connection: Omit<ConnectionAsset, 'asset_type'>): Promise<boolean> {
    if (!connection.recipe_count) {
      return true;
    }

    return this.dialog.openConfirmationDialog(
      $localize`Permanently delete connection?`,
      confirmationDialogFormatter({
        recipeCount: connection.recipe_count,
        connectionName: _.escape(connection.name),
        b: content => `<b>${content}</b>`,
      }),
      {
        width: 624,
        confirmButton: $localize`Delete connection`,
        cancelButton: $localize`Cancel`,
        headerIcon: connectionIcon.id,
        theme: 'negative',
      },
    );
  }

  generateName(adapter: Adapter, existingConnections: Connection[]): string {
    let counter = existingConnections.length;
    let name;

    do {
      counter++;
      name = connectionNameFormatter({title: adapter.title, counter});
    } while (this.getNameValidationError(name, existingConnections));

    return name;
  }

  getNameValidationError(name: string, existingConnections: Connection[], id?: Connection['id']): string {
    if (this.isEmptyName(name)) {
      return $localize`Name can't be blank`;
    }

    if (!this.isUniqueName(id, name, existingConnections)) {
      return $localize`Name already exists`;
    }

    return '';
  }

  isEmptyName(name: string): boolean {
    return !name.trim();
  }

  isUniqueName(id: Connection['id'] | undefined, name: Connection['name'], existingConnections: Connection[]): boolean {
    return existingConnections.every(connection => connection.name !== name || connection.id === id);
  }

  sendMixpanelEventForAction(
    action: string,
    provider: Connection['provider'],
    mixpanelEvents: ConnectionsMixpanelEvents | undefined,
  ) {
    const mixpanelEventInfo = _.get(mixpanelEvents, action);

    if (!mixpanelEventInfo) {
      return;
    }

    let params: MixpanelEventParams = {};
    let event: MixpanelEvent;

    if (Array.isArray(mixpanelEventInfo)) {
      event = mixpanelEventInfo[0];
      params = mixpanelEventInfo[1];
    } else {
      event = mixpanelEventInfo;
    }

    this.mixpanelService.track(event, {...params, provider});
  }

  updateUseManagedConnectionField(shouldShowOnPremSelect: boolean, connection: Connection) {
    if (shouldShowOnPremSelect) {
      const selectedGroupId = Number(connection.secure_gateway_tunnel_id);

      connection.use_managed_connection =
        !selectedGroupId || Boolean(this.authUser.secure_gateway_tunnels.find(([, id]) => id === selectedGroupId)?.[2]);
    } else {
      delete connection.use_managed_connection;
    }
  }

  generateCustomOauthField(connection: Connection): SchemaField {
    const options: Picklist = this.authUser.custom_oauth_keys
      .filter(key => key.provider === connection.provider)
      .map(key => [key.name, key.id]);

    return {
      name: 'custom_oauth_key_id',
      control_type: 'select',
      label: $localize`Custom OAuth profile`,
      placeholder: $localize`Select OAuth profile`,
      optional: true,
      options,
      hint: customOauthProfileFieldHintFormatter({
        customOauthKeyId: connection.custom_oauth_key_id,
        a1: content => `<a href="/custom_oauth_keys/" target="_blank">${content}</a>.`,
        a2: content => `<a href="/custom_oauth_keys/${connection.custom_oauth_key_id}" target="_blank">${content}</a>.`,
        a3: content =>
          `<a href="/custom_oauth_keys/new?provider=${connection.provider}" target="_blank">${content}</a>.`,
      }),
    };
  }

  generateSecureAgentField(hasAdapter: boolean, secureTunnelRequired: boolean): SchemaField | null {
    if (!hasAdapter) {
      return null;
    }

    const label = secureTunnelRequired ? $localize`On-prem group` : $localize`Connection type`;
    const secureGroupsOptions: PicklistItem[] = this.authUser.secure_gateway_tunnels.map(([name, id]) => [name, id]);
    const hintLink = `<a href="https://docs.workato.com/on-prem/agents/connection.html" target="_blank">${$localize`Learn more`}</a>`;
    const hint = secureTunnelRequired
      ? $localize`You’ll use agents in this on-prem group to connect your application. ${hintLink}`
      : $localize`If you want to connect using an on-prem group, please choose one from the dropdown. ${hintLink}`;

    return {
      name: 'secure_gateway_tunnel_id',
      control_type: 'select',
      label,
      optional: false,
      options: secureTunnelRequired ? secureGroupsOptions : [[$localize`Cloud`, ''], ...secureGroupsOptions],
      hint,
      default: secureTunnelRequired ? '' : $localize`Cloud`,
      placeholder: $localize`Choose on-prem group`,
    };
  }

  getErrorStatus(message = $localize`Server error`, errors?: Hash<string[]>): ConnectionStatus {
    if (errors && _.isPlainObject(errors)) {
      const name = Object.keys(errors)[0];

      message = `${name} ${errors[name][0]}`;
    }

    return {
      success: false,
      message,
    };
  }

  getDisconnectDescription(connection: Connection): string {
    const pieces: string[] = [];

    if (connection.secrets_manager_connection) {
      pieces.push($localize`usage of your stored secrets in your connections`);
    }

    if (connection.alr_connection) {
      pieces.push($localize`streaming of your audit log data`);
    }

    if (connection.traffic_mirroring_connection) {
      pieces.push($localize`traffic to your Mirroring destination`);
    }

    if (this.isExtendedConnection(connection) && connection.running_recipe_count > 0) {
      pieces.push(
        activeRecipesCountFormatter({recipeCount: connection.running_recipe_count, b: content => `<b>${content}</b>`}),
      );
    }

    if (connection.proxy_api_endpoints_count > 0) {
      pieces.push(
        apiProxyEndpointsCountFormatter({
          endpointsCount: connection.proxy_api_endpoints_count,
          b: content => `<b>${content}</b>`,
        }),
      );
    }

    if (!pieces.length) {
      return '';
    }

    const result = toSentence(pieces);

    return $localize`${result.charAt(0).toUpperCase()}:capitalizedFirstLetter:${result.slice(1)}:messageRest: will be affected.`;
  }
}
